<template>
  <div class="SectionTenantList-wrapper">
    <section-list v-bind="params" />
  </div>
</template>

<script>
import SectionList from '../section/SectionList'
import { getListURL } from './api'

export default {
  name: 'SectionTenantList',
  components: {
    SectionList
  },
  data () {
    return {
      params: {
        type: 'tenant',
        searchUrl: getListURL,
        formName: 'sectionTenantForm'
      }
    }
  }
}
</script>
